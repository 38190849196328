/* .App {
  text-align: center;
} */

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #efeff4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  font-size: calc(10px + 1vmin);
  color: #8e8e93;
}

.app-name {
  font-size: calc(10px + 2vmin);
  color: #4a4a4a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: center;
}

.privacy, .licenses {
  padding: 10px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.store-links-box {
  margin-bottom: 10px;
}

.store-links:first-child {
  margin-right: 8px;
}

a.store-links {
  text-decoration: none;
}

.store-links img {
  height: 40px;
}